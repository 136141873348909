import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import { Blocks } from 'react-loader-spinner'

const AppSurveyMainLazy = React.lazy(() => {
  if (window.location.pathname !== '/landingPage2') {
    return import('./AppSurveyMain');
  }
  return () => null;
});

const OpenReactTemplateLazy = React.lazy(() => {
  return import('./openReactTemplate/OpenReactTemplate');
});

export default function SurveyJSReactApplication() {
  React.useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "G-NP0TGVZXTX"
      },
      {
        trackingId: "AW-10843945043"
      }
    ]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });


    const callback = function () {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };
    

    ReactGA.event({
      action: 'conversion',
      category: 'AW-10843945043/1OhpCPrN25EYENPw5bIo',
      label: 'AW-10843945043/1OhpCPrN25EYENPw5bIo',
      event_callback: callback
    });
    
    ReactGA.event({
      action: 'conversion',
      category: 'AW-10843945043/Qnm4CP3N25EYENPw5bIo',
      label: 'AW-10843945043/Qnm4CP3N25EYENPw5bIo',
      event_callback: callback
    });
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <React.Suspense fallback={<Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />}>
            <OpenReactTemplateLazy />
          </React.Suspense>
        </Route>
        <Route path="*">
          <React.Suspense fallback={<Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />}>
            <AppSurveyMainLazy />
          </React.Suspense>
        </Route>
      </Switch>
    </Router>
  )
}
